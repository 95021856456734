@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  align-self: end;

  & > .button-base {
    width: 120px;
  }
}

.button-base {
  min-width: 100px;
  height: $button-height;
  padding: $button-padding-top $button-padding-left;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  user-select: none;
  border: none;
  border-radius: $button-border-radius;
  transition: background-color 0.5s, border 0.5s, color 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $button-gap;
  touch-action: manipulation;

  &:disabled {
    cursor: default;
  }

  & > app-icon {
    transition: color 0.5s;
  }
}

.button-primary {

  @extend .button-base;

  color: $button-primary-color;
  background-color: $button-primary-background-color;
  border: 1px solid $button-primary-border-color;

  & app-icon {
    width: $icon-size;
    height: $icon-size;
    color: $button-primary-color;
  }

  &:hover {
    color: $button-primary-hover-color;
    background-color: $button-primary-hover-background-color;

    & app-icon {
      color: $button-primary-hover-color;
    }
  }

  &:disabled {
    color: $button-primary-disabled-color;
    border-color: $button-primary-disabled-border-color;
    background-color: $button-primary-disabled-background-color;

    & app-icon {
      color: $button-primary-disabled-color;
    }
  }
}

.button-primary-inverted {

  @extend .button-base;

  color: $button-primary-inverted-color;
  background-color: $button-primary-inverted-background-color;
  border: 1px solid $button-primary-inverted-border-color;

  & app-icon {
    width: $icon-size;
    height: $icon-size;
    color: $button-primary-inverted-color;
  }

  &:hover {
    color: $button-primary-inverted-hover-color;
    background-color: $button-primary-inverted-hover-background-color;
    border-color: $button-primary-inverted-hover-border-color;

    & app-icon {
      color: $button-primary-inverted-hover-color;
    }
  }

  &:disabled {
    color: $button-primary-inverted-disabled-color;
    border-color: $button-primary-inverted-disabled-border-color;
    background-color: $button-primary-inverted-disabled-background-color;

    & app-icon {
      color: $button-primary-inverted-disabled-color;
    }
  }
}

.button-secondary {

  @extend .button-base;

  color: $button-secondary-color;
  background-color: $button-secondary-background-color;
  border: 1px solid $button-secondary-border-color;

  &:hover {
    color: $button-secondary-hover-color;
    background-color: $button-secondary-hover-background-color;
  }

  &:disabled {
    color: $button-secondary-disabled-color;
    background-color: $button-secondary-disabled-background-color;
    border-color: $button-secondary-disabled-border-color;
  }
}

.button-secondary-inverted {

  @extend .button-base;

  color: $button-secondary-inverted-color;
  background-color: $button-secondary-inverted-background-color;
  border: 1px solid $button-secondary-inverted-border-color;

  &:hover {
    color: $button-secondary-inverted-hover-color;
    background-color: $button-secondary-inverted-hover-background-color;
    border-color: $button-secondary-inverted-hover-border-color;
  }

  &:disabled {
    color: $button-secondary-inverted-disabled-color;
    background-color: $button-secondary-inverted-disabled-background-color;
    border-color: $button-secondary-inverted-disabled-border-color;
  }
}

.button-danger {

  @extend .button-base;

  background-color: transparent;
  border: 1px solid $danger-1100;
  color: $text-danger;

  & app-icon {
    width: $icon-size;
    height: $icon-size;
    color: $danger-100;
  }

  &:hover {
    background-color: $danger-1000;
  }

  &:disabled {
    color: $danger-1000;
    background-color: transparent;

    & app-icon {
      color: $danger-900;
    }
  }
}

.button-icon-base {
  min-width: $field-height;
  width: $field-height;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.button-icon-primary {
  @extend .button-primary;
  @extend .button-icon-base;
}

.button-icon-primary-inverted {
  @extend .button-primary-inverted;
  @extend .button-icon-base;
}

.button-icon-secondary {
  @extend .button-secondary-inverted;
  @extend .button-icon-base;
}

.button-icon-danger {
  @extend .button-danger;
  @extend .button-icon-base;
}

.button-more {
  @extend .button-icon-secondary;

  border: none;
  min-width: 30px;
  width: 30px;

  & > app-icon {
    transition: color 0.5s;
    color: $secondary;
  }

  &:hover {
    background-color: transparent;

    & > app-icon {
      color: $secondary-200;
    }
  }
}

@import "../../assets/styles/colors";

// Resolution boundaries
$extra-large: 1200px;
$large: 1032px;
$medium: 768px;
$small: 500px;

// Z-index
$z-index-header: 500;
$z-index-menu: 400;
$z-index-actions: 425;
$z-index-dialog: 999;
$z-index-tab: 20;
$z-index-list: 1;
$z-index-toolbar: 450;


// Text
$text-primary: $primary-100;
$text-primary-inverted: $primary-1100;

$text-secondary: $secondary-100;
$text-secondary-inverted: $secondary-1100;

$text-danger: $danger-100;
$text-danger-inverted: $danger-1100;

$title-font: 'logofont', sans-serif;
$title-font-size-large: 26px;
$title-font-size-small: 22px;
$title-color: $primary-700;

$subtitle-color: $secondary;
$subtitle-font-size: 22px;

// Icons
$icon-size: 20px;

// Header
$header-height: 48px;
$header-color: $primary-100;
$header-background-color: $primary-1000;
$header-border-color: $primary-800;

// PageState
$page-font-size: 14px;
$page-background-color: $neutral-1400;
$page-padding: 32px;
$page-padding-medium: 16px;
$page-padding-small: 8px;
$page-gap: 32px;
$page-gap-medium: 32px;
$page-gap-small: 32px;

// Menu
$menu-width: 256px;
$menu-auto-expand-width: calc($large + $menu-width + 50px);
$menu-background-color: $secondary-1100;
$menu-border-color: $neutral-900;
$menu-group-background-color: $secondary-1100;

// Cards
$cards-gap: 32px;
$cards-gap-medium: 32px;
$cards-gap-small: 32px;

// Card
$card-width: 860px;
$card-padding: 0;
$card-border-radius: 0;
$card-gap: 8px;
$card-border-color: transparent;
$card-background-color: $page-background-color;

// Fields
$field-height: 38px;
$field-border-radius: 8px;
$field-color: $text-secondary;
$field-background-color: $secondary-1100;
$field-border-color: $secondary-900;
$field-hover-background-color: $secondary-1000;
$field-hover-border-color: $secondary-800;
$field-disabled-background-color: $field-background-color;

$field-primary-color: $text-primary;
$field-primary-background-color: $primary-1100;
$field-primary-border-color: $primary-900;
$field-primary-hover-background-color: $primary-1000;
$field-primary-hover-border-color: $primary-800;
$field-primary-disabled-background-color: $field-primary-background-color;

$field-info-color: $field-color;
$field-info-background-color: $field-background-color;
$field-info-border-color: $field-border-color;

$field-error-color: $text-primary;
$field-error-background-color: $primary-1100;
$field-error-border-color: $primary-900;

$field-actions-height: 66px;
$field-actions-padding: $card-gap;

// Buttons
$button-height: 38px;
$button-border-radius: 19px;
$button-padding-top: 6px;
$button-padding-left: 12px;
$button-gap: 4px;

$button-primary-color: $primary-100;
$button-primary-background-color: $primary-1000;
$button-primary-border-color: $primary-900;
$button-primary-hover-color: $button-primary-color;
$button-primary-hover-background-color: $primary-900;
$button-primary-disabled-color: mix($button-primary-color, $page-background-color, 20%);
$button-primary-disabled-background-color: mix($button-primary-background-color, $page-background-color, 20%);
$button-primary-disabled-border-color: mix($button-primary-border-color, $page-background-color, 20%);

$button-primary-inverted-color: $button-primary-color;
$button-primary-inverted-background-color: transparent;
$button-primary-inverted-border-color: transparent;
$button-primary-inverted-hover-color: $button-primary-hover-color;
$button-primary-inverted-hover-background-color: $primary-1000;
$button-primary-inverted-hover-border-color: $primary-900;
$button-primary-inverted-disabled-color: rgba($button-primary-inverted-color, 0.4);
$button-primary-inverted-disabled-background-color: transparent;
$button-primary-inverted-disabled-border-color: transparent;

$button-secondary-color: $secondary-100;
$button-secondary-background-color: $secondary-1000;
$button-secondary-border-color: $secondary-900;
$button-secondary-hover-color: $button-secondary-color;
$button-secondary-hover-background-color: $secondary-900;
$button-secondary-disabled-color: mix($button-secondary-color, $secondary-1400, 20%);
$button-secondary-disabled-background-color: mix($button-secondary-background-color, $secondary-1400, 20%);
$button-secondary-disabled-border-color: mix($button-secondary-border-color, $secondary-1400, 20%);

$button-secondary-inverted-color: $button-secondary-color;
$button-secondary-inverted-background-color: transparent;
$button-secondary-inverted-border-color: transparent;
$button-secondary-inverted-hover-color: $button-secondary-hover-color;
$button-secondary-inverted-hover-background-color: $secondary-1000;
$button-secondary-inverted-hover-border-color: $secondary-900;
$button-secondary-inverted-disabled-color: rgba($button-secondary-inverted-color, 0.4);
$button-secondary-inverted-disabled-background-color: transparent;
$button-secondary-inverted-disabled-border-color: transparent;

// List
$list-item-min-height: 64px;
$list-border-size: 1px;
$list-border-color: $button-secondary-border-color;
$list-inner-border-color: rgba($secondary-1000, 0.8);
$list-border-radius: $field-border-radius;
$list-background-color: transparent;

$list-group-color: $secondary-200;
$list-group-background-color: $secondary-1400;

$list-item-padding-top: 8px;
$list-item-padding-left: 6px;
$list-item-gap: 4px;

$list-item-primary-color: $primary-200;
$list-item-faded-primary-color: $primary;
$list-item-primary-background-color: $primary-1100;
$list-item-primary-hover-background-color: $primary-1000;

$list-item-secondary-color: $secondary-200;
$list-item-faded-secondary-color: $secondary;
$list-item-secondary-background-color: $secondary-1100;
$list-item-secondary-hover-background-color: $secondary-1000;

// Tiles
$tiles-gap: 16px;
$tiles-gap-small: 8px;

$tile-height: 128px;
$tile-border-radius: 16px;
$tile-image-size: 38px;

$tile-top-padding: 8px;
$tile-bottom-height: 52px;
$tile-bottom-padding: 8px;

$tile-primary-background-color: $list-item-primary-background-color;
$tile-primary-border-color: $button-primary-border-color;
$tile-primary-hover-background-color: $list-item-primary-hover-background-color;
$tile-primary-hover-border-color: $tile-primary-border-color;
$tile-primary-top-color: rgba($text-primary, 0.5);
$tile-primary-bottom-color: $button-primary-color;
$tile-primary-bottom-background-color: $tile-primary-background-color;
$tile-primary-bottom-hover-background-color: $tile-primary-hover-background-color;
$tile-primary-bottom-border-color: transparent;

$tile-secondary-background-color: $list-item-secondary-background-color;
$tile-secondary-border-color: $button-secondary-border-color;
$tile-secondary-hover-background-color: $list-item-secondary-hover-background-color;
$tile-secondary-hover-border-color: $tile-secondary-border-color;
$tile-secondary-top-color: rgba($text-secondary, 0.5);
$tile-secondary-bottom-color: $button-secondary-color;
$tile-secondary-bottom-background-color: $tile-secondary-background-color;
$tile-secondary-bottom-hover-background-color: $tile-secondary-hover-background-color;
$tile-secondary-bottom-border-color: transparent;

// Tabs
$tab-height: 60px;
$tab-gap: $card-gap;
$tab-padding: $card-gap;
$tab-padding-top: calc($tab-padding * 2);
$tab-border-color: $neutral-900;
$tab-background-color: $neutral-1200;
$tab-min-width: 100px;
$tab-hover-color: $primary;
$tab-hover-border-color: $primary;
$tab-selected-color: $primary;
$tab-selected-border-color: $primary;

// Form
$form-gap: 16px;

// Dialog
$dialog-border-radius: calc($list-border-radius + 8px);
$dialog-header-height: 54px;
$dialog-background-color: $card-background-color;
$dialog-border-color: $secondary-1000;

$dialog-message-color: $text-secondary;

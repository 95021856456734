@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.text-unselectable {
  user-select: none;
}

.title {
  @extend .text-unselectable;

  text-align: left;
  font-size: $title-font-size-large;
  font-family: $title-font;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  width: $card-width;
  color: $title-color;
}

@media (max-width: $large) {
  .title {
    width: inherit;
  }
}

.subtitle {
  @extend .text-unselectable;

  font-size: 22px;
  font-family: $title-font;
  min-width: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  color: $subtitle-color;

  & app-icon {
    margin-left: 4px;
    margin-right: 8px;
  }

  & span {
    @extend .text-faded-secondary;
    margin: 0 4px;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-faded-secondary {
  color: $secondary;
}

.text-faded-primary {
  color: rgba($primary, 0.5);
}

@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.dialog-glass-pane {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: $z-index-dialog;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  position: fixed;

  min-width: 300px;
  min-height: 60px;
  max-width: calc(100vw - 16px);
  background-color: $dialog-background-color;
  border-radius: $dialog-border-radius;
  border: 1px solid $dialog-border-color;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
}

.dialog-header {
  position: relative;
  font-size: 24px;
  font-family: 'logofont', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: $title-color;
  height: $dialog-header-height;
  user-select: none;
}

.dialog-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  cursor: pointer;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  padding: 0 8px 8px 8px;
}

.dialog-scroll {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: calc(75vh - $header-height - 16px);
  overflow-y: auto;
}

.dialog-message {
  color: $dialog-message-color;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 8px 8px 8px;
}

@import "assets/styles/theme";

//
// Fonts
//

@font-face {
  font-family: 'logofont';
  src: url('/assets/fonts/teko.ttf');
}

//
// Global changes
//

html, body {
  background-color: $page-background-color;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: $page-font-size;
}

textarea {
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

img, svg {
  user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.list {
  display: flex;
  flex-direction: column;
  border: $list-border-size solid $list-border-color;
  background-color: $list-background-color;
  border-radius: $list-border-radius;
  overflow: hidden;
}

.list-group {
  pointer-events: none;
  padding: 5px 4px 4px 4px;
  color: $list-group-color;
  background-color: $list-group-background-color;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.list-item-base {
  position: relative;
  min-height: $list-item-min-height;
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
  transition: background-color 0.5s;
  color: $list-item-secondary-color;

  &:not(:last-child) {
    border-bottom: 1px solid $list-inner-border-color;
  }
}

.list-item-secondary {
  @extend .list-item-base;

  background-color: $list-item-secondary-background-color;

  &:hover {
    background-color: $list-item-secondary-hover-background-color;
  }
}

.list-item-primary {
  @extend .list-item-base;

  color: $list-item-primary-color;
  background-color: $list-item-primary-background-color;

  &:hover {
    background-color: $list-item-primary-hover-background-color;
  }
}

.list-item-disabled {
  cursor: default !important;
}

.list-text-base {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  z-index: $z-index-list;
  gap: 2px;
  padding: $list-item-padding-top $list-item-padding-left;
  transition: background-color 0.5s;

  & div:not(:first-child) {
    font-size: 12px;
  }
}


.list-text-primary {
  @extend .list-text-base;

  & div:not(:first-child) {
    color: $list-item-faded-primary-color;
  }
}

.list-text-secondary {
  @extend .list-text-base;

  & div:not(:first-child) {
    color: $list-item-faded-secondary-color;
  }
}

.list-text-more {
  @extend .list-text-base;
  align-items: center;
  color: $secondary-300;

  & div:not(:first-child) {
    color: $list-item-faded-secondary-color;
  }
}

.list-text-faded {
  color: $secondary-700;
}

.list-text-message {
  @extend .list-text-base;

  align-items: center;
  cursor: default;

  & div:not(:first-child) {
    color: $secondary;
  }

  & > app-icon {
    height: $icon-size;
    width: $icon-size;
    color: $secondary;
    margin-right: 4px;
  }
}

.list-text-message-clickable {
  @extend .list-text-message;

  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.list-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: $z-index-list;
  padding: 8px;
  font-size: 14px;
}

.list-actions {
  z-index: $z-index-list;
  display: flex;
  flex-direction: row;
  gap: 2px;
  min-width: max-content;
}

.list-action-base {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  padding: 0 10px 0 8px;
  background-color: transparent;
  width: 118px;
  border-radius: 0;
  height: inherit;

  &:hover {
    border-color: transparent;
  }
}

.list-action-secondary {
  @extend .button-secondary-inverted;
  @extend .list-action-base;
}

.list-action-primary {
  @extend .button-primary-inverted;
  @extend .list-action-base;
}

.list-action-icon {
  @extend .button-secondary-inverted;
  @extend .list-action-base;

  padding: 0;
  min-width: inherit;
  width: $list-item-min-height;
}

.list-icon-base {
  display: flex;
  flex-direction: column;
  gap: $list-item-gap;
  justify-content: center;
  align-items: center;

  & app-icon {
    width: $icon-size;
    height: $icon-size;
  }
}

.list-icon-primary {
  @extend .list-icon-base;
  color: $primary;

  & app-icon {
    color: $primary;
  }
}

.list-icon-primary-inverted {
  @extend .list-icon-base;

  & app-icon {
    color: $button-primary-color;
  }
}

.list-icon-secondary {
  @extend .list-icon-base;

  & app-icon {
    color: $secondary-300;
  }
}

.list-icons {
  display: flex;
  flex-direction: row;
  gap: calc($list-item-min-height / 2 - $icon-size / 2);
  padding: 0 calc($list-item-min-height / 2 - $icon-size / 2);
}

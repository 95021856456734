@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $page-gap;
}

.page-with-actions {
  @extend .page;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: $cards-gap;
}

.card {
  // background-color: $card-background-color;
  border-radius: $card-border-radius;
  display: flex;
  flex-direction: column;
  gap: $card-gap;
  padding: $card-padding;
  width: 100%;
  border: 1px solid $card-border-color;
}

.card-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  min-height: $field-height;
}

.header-actions {
  display: flex;
  flex-direction: row;
  gap: $card-gap;
}

.header-action-group {
  display: flex;
  flex-direction: row;
  gap: $card-gap;
}

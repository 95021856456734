@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.form {
  display: flex;
  flex-direction: column;
  gap: $form-gap;
  padding: 4px 0;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.field-columns-50-50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.field-label {
  color: $secondary;
  font-size: 12px;
}

.field-group {
  font-size: 14px;
  padding: 6px;
}

.field-error {
  color: $field-error-color;
  font-size: 12px;
  background-color: $field-error-background-color;
  border: 1px solid $field-error-border-color;
  border-radius: $field-border-radius;
  padding: 4px;
}

.field-info {
  color: $field-info-color;
  font-size: 12px;
  background-color: $field-info-background-color;
  border: 1px solid $field-info-border-color;
  border-radius: $field-border-radius;
  padding: 4px;
}

.field-text {
  background: $field-background-color;
  color: $field-color;
  height: $field-height;
  width: 100%;
  padding: 0 6px;
  border: 1px solid $field-border-color;
  border-radius: $field-border-radius;
  outline: none;
  box-shadow: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:hover {
    box-shadow: none;
    outline: none;
    background-color: $field-hover-background-color;
    border-color: $field-hover-border-color;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    background-color: $field-disabled-background-color;
  }

  &::placeholder {
    color: $secondary-700;
  }
}

.field-text-primary {
  background: $field-primary-background-color;
  color: $field-primary-color;
  height: $field-height;
  width: 100%;
  padding: 0 6px;
  border: 1px solid $field-primary-border-color;
  border-radius: $field-border-radius;
  outline: none;
  box-shadow: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:hover {
    box-shadow: none;
    outline: none;
    background-color: $field-primary-hover-background-color;
    border-color: $field-primary-hover-border-color;
  }

  &:active {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    background-color: $field-primary-disabled-background-color;
  }

  &::placeholder {
    color: $primary-400;
  }
}

.field-text-with-actions {
  display: flex;
  gap: 4px;
  align-items: center;

  & > *:first-child {
    width: 100%;
  }

  & > *:last-child:not(:first-child) {
    flex: 0;
  }
}

textarea.field-text {
  min-height: $field-height;
  resize: vertical;
  padding: 17px 6px;
  height: calc($field-height - 12px);
}

.field-message {
  background: $field-background-color;
  color: $secondary;
  height: $field-height;
  width: 100%;
  padding: 0 6px;
  border: 1px solid $field-border-color;
  border-radius: $field-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: $field-actions-padding;
  padding-top: $form-gap;
}

.field-action {
}

.field-action-secondary {
  @extend .field-action;
  @extend .button-secondary;
}

.field-action-primary {
  @extend .field-action;
  @extend .button-primary;
}

@media (max-width: $medium) {

  .field-actions {
    padding: $field-actions-padding;
    position: fixed;
    height: $field-actions-height;
    width: 100vw;
    bottom: 0;
    left: 0;
    justify-content: inherit;
    align-items: center;
    border-top: 1px solid $menu-border-color;
    background-color: $menu-background-color;
    z-index: $z-index-toolbar;
  }

  .field-action {
    flex: 1;
    min-width: 0;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.tabs-container {
  position: relative;
  height: $tab-height;
  min-height: $tab-height;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $tab-border-color;
  width: 100%;
  flex: 1;
  background-color: $tab-background-color;
}

.tabs-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $tab-gap;
  width: $card-width;
}

.tabs-center-with-back {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $tab-gap;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  min-width: $tab-min-width;
  transition: color 0.5s, border-color 0.5s;
  padding: $tab-padding-top $tab-padding $tab-padding $tab-padding;
  height: $tab-height;

  &:hover {
    color: $tab-hover-color;
  }
}

.tab-selected {
  @extend .tab;

  font-weight: bold;
  color: $tab-selected-color;
  border-bottom: 2px solid $tab-selected-border-color;
  cursor: default;
}

.tab-disabled {
  @extend .tab;

  color: $button-secondary-disabled-color;
  cursor: default;

  &:hover {
    color: $button-secondary-disabled-color;
  }
}

.tab-back {
  @extend .button-icon-secondary;
}

@media (max-width: $small) {
  .tabs-center {
    gap: 0;
  }

  .tabs {
    gap: 0;
  }
}

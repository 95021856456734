// Main colors
$primary: #ff3c93;
$secondary: #7da8b2;
$neutral: #7fa0a8;
$success: #0ebee7;
$danger: #ff3c4c;

$white: #fff;
$black: #000;

// Primary variations
$primary-20: lighten($primary, 40);
$primary-50: lighten($primary, 36);
$primary-100: lighten($primary, 30);
$primary-200: lighten($primary, 21);
$primary-300: lighten($primary, 12);
$primary-400: lighten($primary, 6);
$primary-600: darken($primary, 0);
$primary-700: darken($primary, 9);
$primary-800: darken($primary, 15);
$primary-900: darken($primary, 20);
$primary-1000: darken($primary, 32);
$primary-1100: darken($primary, 40);
$primary-1200: darken($primary, 45);
$primary-1300: darken($primary, 47);
$primary-1400: darken($primary, 49);

// Secondary variations
$secondary-20: lighten($secondary, 40);
$secondary-50: lighten($secondary, 36);
$secondary-100: lighten($secondary, 30);
$secondary-200: lighten($secondary, 21);
$secondary-300: lighten($secondary, 12);
$secondary-400: lighten($secondary, 6);
$secondary-600: darken($secondary, 0);
$secondary-700: darken($secondary, 9);
$secondary-800: darken($secondary, 15);
$secondary-900: darken($secondary, 20);
$secondary-1000: darken($secondary, 32);
$secondary-1100: darken($secondary, 40);
$secondary-1200: darken($secondary, 45);
$secondary-1300: darken($secondary, 47);
$secondary-1400: darken($secondary, 49);

// Neutral
$neutral-20: lighten($neutral, 40);
$neutral-50: lighten($neutral, 36);
$neutral-100: lighten($neutral, 30);
$neutral-200: lighten($neutral, 21);
$neutral-300: lighten($neutral, 12);
$neutral-400: lighten($neutral, 6);
$neutral-600: darken($neutral, 0);
$neutral-700: darken($neutral, 9);
$neutral-800: darken($neutral, 15);
$neutral-900: darken($neutral, 20);
$neutral-1000: darken($neutral, 32);
$neutral-1100: darken($neutral, 40);
$neutral-1200: darken($neutral, 45);
$neutral-1300: darken($neutral, 47);
$neutral-1400: darken($neutral, 49);

// Success
$success-20: lighten($success, 40);
$success-50: lighten($success, 36);
$success-100: lighten($success, 30);
$success-200: lighten($success, 21);
$success-300: lighten($success, 12);
$success-400: lighten($success, 6);
$success-600: darken($success, 0);
$success-700: darken($success, 9);
$success-800: darken($success, 15);
$success-900: darken($success, 20);
$success-1000: darken($success, 32);
$success-1100: darken($success, 40);
$success-1200: darken($success, 45);
$success-1300: darken($success, 47);
$success-1400: darken($success, 49);

// Danger
$danger-20: lighten($danger, 40);
$danger-50: lighten($danger, 36);
$danger-100: lighten($danger, 30);
$danger-200: lighten($danger, 21);
$danger-300: lighten($danger, 12);
$danger-400: lighten($danger, 6);
$danger-600: darken($danger, 0);
$danger-700: darken($danger, 9);
$danger-800: darken($danger, 15);
$danger-900: darken($danger, 20);
$danger-1000: darken($danger, 32);
$danger-1100: darken($danger, 40);
$danger-1200: darken($danger, 45);
$danger-1300: darken($danger, 47);
$danger-1400: darken($danger, 49);

// Other
$black-transparent: rgba(0, 0, 0, 0.5);

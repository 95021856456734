@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $tiles-gap;
}

@media (max-width: $medium) {
  .tiles {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $small) {
  .tiles {
    gap: $tiles-gap-small;
    grid-template-columns: repeat(2, 1fr);
  }
}

.tile-base {
  height: $tile-height;
  min-height: $tile-height;
  border-radius: $tile-border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s, border 0.5s;
  overflow: hidden;

  .tile-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 4px;
    padding: $tile-top-padding;
    min-width: 50%;

    & > app-icon {
      width: $tile-image-size;
      height: $tile-image-size;
    }
  }

  .tile-bottom {
    text-align: center;
    align-content: center;
    width: 100%;
    transition: background-color 0.5s, border 0.5s;
    height: $tile-bottom-height;
    padding: $tile-bottom-padding;
    border-top: 1px solid transparent;
  }
}

.tile-primary {
  @extend .tile-base;

  background-color: $tile-primary-background-color;
  border: 1px solid $tile-primary-border-color;

  &:hover {
    background-color: $tile-primary-hover-background-color;
    border-color: $tile-primary-hover-border-color;

    & .tile-bottom {
      background-color: $tile-primary-bottom-hover-background-color;
    }
  }

  .tile-top {
    color: $tile-primary-top-color;
  }

  .tile-bottom {
    background-color: $tile-primary-bottom-background-color;
    color: $tile-primary-bottom-color;
    border-color: $tile-primary-bottom-border-color;
  }
}

.tile-secondary {
  @extend .tile-base;

  background-color: $tile-secondary-background-color;
  border: 1px solid $tile-secondary-border-color;

  &:hover {
    background-color: $tile-secondary-hover-background-color;
    border-color: $tile-secondary-hover-border-color;

    & .tile-bottom {
      background-color: $tile-secondary-bottom-hover-background-color;
    }
  }

  .tile-top {
    color: $tile-secondary-top-color;
  }

  .tile-bottom {
    background-color: $tile-secondary-bottom-background-color;
    color: $tile-secondary-bottom-color;
    border-color: $tile-secondary-bottom-border-color;
  }
}

